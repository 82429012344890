const dataLayerPush = (data: {}) => {
  // For dev env this is not initialized
  if (window.dataLayer) {
    window.dataLayer.push({ ...data, timestamp: new Date().getTime() })
  }
}

export const trackEvent = (event: string, data?: object) => {
  dataLayerPush({
    event,
    ...data,
  })
}

export const pageView = (path: string) => {
  trackEvent('page_view', {
    page: {
      pageURL: path,
    },
  })
}
