import platform from 'platform'
import { useEffect } from 'react'
import { captureException } from '@sentry/core'

const LOCAL_STORAGE_KEY = 'campaign'

const individualProperties = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'fbclid',
]

const utmProperties = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

export type TrackingParams = {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
  query: string
  ua: string
  referer: string
  path: string
  kindCommercial?: boolean
  timestamp?: string
  fbclid?: string
}

function utmParamsEquals(subject: TrackingParams, comparison: TrackingParams) {
  const utmsStringA = mapUtmsString(subject)
  const utmsStringB = mapUtmsString(comparison)
  if (utmsStringA === null && utmsStringB === null) {
    return false
  }
  return utmsStringA === utmsStringB
}

function mapUtmsString(source: TrackingParams): string | null {
  return source
    ? utmProperties
        .map(property => source[property as keyof TrackingParams])
        .join()
    : null
}

function getUserAgentSimple() {
  return `${platform.os?.family
    ?.replace(/\s/g, '')
    .toLowerCase()}-${platform.name?.toLowerCase()}`
}

function utmsParamsMap() {
  const search = window.location.search
  const searchParams = new URLSearchParams(search)
  return Array.from(searchParams.entries())
    .filter(([key, value]) => individualProperties.includes(key))
    .reduce<{
      [key: string]: string
    }>((acc, [key, value]) => {
      acc[key] = value

      return acc
    }, {})
}

function tryGetStoredParams(): TrackingParams | null {
  try {
    const storedParams = sessionStorage.getItem(LOCAL_STORAGE_KEY)
    return storedParams ? JSON.parse(storedParams) : null
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error getting stored tracking params', e)
    captureException(e)
    return null
  }
}

function storeTrackingParams(): {
  isNewCampaign: boolean
} | null {
  const originalParams = tryGetStoredParams()

  const location = window.location
  const referer = document?.referrer
  const ua = getUserAgentSimple()
  const utms = utmsParamsMap()
  const kindCommercial = !!utms['utm_medium']
  const timestamp = new Date().toISOString()

  const freshParams = {
    ...utms,
    referer,
    path: location.pathname,
    query: location.search,
    ua,
    kindCommercial,
    timestamp,
  }

  const refreshCookies = shouldRefresh(originalParams, freshParams)
  if (refreshCookies) {
    sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(freshParams))
  }
  return {
    isNewCampaign: refreshCookies,
  }
}

const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000

function shouldRefresh(
  originalParams: TrackingParams | null,
  freshParams: TrackingParams,
) {
  if (!freshParams) return false
  const isInternal =
    !!freshParams.referer &&
    (/^https?:\/\/(www\.)?vatomat\.cz/i.test(freshParams.referer) ||
      /^https?:\/\/localhost([:/])/.test(freshParams.referer))
  if (isInternal) return false

  if (!originalParams) return true

  const hasNoSetUtmParams =
    Object.entries(freshParams).filter(
      ([key, value]) => individualProperties.includes(key) && !!value,
    ).length == 0

  const isNewReferer =
    freshParams.referer && originalParams.referer !== freshParams.referer

  if (isNewReferer) return true

  if (hasNoSetUtmParams) return false

  // always refresh with kindCommercial
  if (freshParams.kindCommercial) return true

  // kindCommercial params locked for 30 days
  if (
    originalParams.kindCommercial &&
    originalParams.timestamp &&
    new Date().getTime() - new Date(originalParams.timestamp).getTime() <
      THIRTY_DAYS
  ) {
    return false
  }

  return !utmParamsEquals(freshParams, originalParams)
}

export function getStoredUrlTrackingParams(): TrackingParams | null {
  return tryGetStoredParams()
}

export function UrlParamsTracking() {
  useEffect(() => {
    try {
      storeTrackingParams()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error storing tracking params', e)
      captureException(e)
    }
  }, [])
  return null
}
